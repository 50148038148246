import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import Spinner from "react-spinkit";
import styled from "styled-components";
import { getRecentProjectSubmissions, fetchUserDataByUid } from "api/db";

const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const Gallery = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    getRecentProjectSubmissions(snapshot => {
      let data = snapshot.val();
      let key = snapshot.key;
      fetchUserDataByUid(data.uid).once("value", function(snapshot) {
        let userData = snapshot.val();
        let keyObject = { key: key };
        const object = { ...data, ...userData, ...keyObject };
        if (
          !userData.tags ||
          (userData.tags && !userData.tags.includes("team"))
        ) {
          setProjects(projects => projects.concat(object));
        }
      });
    });
  }, []);

  return (
    <>
      <Helmet
        title="Gallery | Enlight"
        meta={[
          {
            name: "description",
            content: "View hundreds of project submissions on Enlight"
          },
          {
            name: "og:title",
            content: "Gallery | Enlight"
          },
          {
            name: "og:description",
            content: "View hundreds of project submissions on Enlight"
          },
          {
            name: "og:url",
            content: "https://enlight.nyc/gallery"
          },
          {
            name: "og:image",
            content: "https://enlight.nyc/img/courses/web-development/share.png"
          },
          {
            name: "twitter:title",
            content: "Gallery | Enlight"
          },
          {
            name: "twitter:image",
            content: "https://enlight.nyc/img/courses/web-development/share.png"
          }
        ]}
      />
      <h1 className="text-5xl font-extrabold text-center text-gray-800 tracking-tight mt-3">
        Gallery
      </h1>
      <h2 className="text-2xl text-center text-gray-700 tracking-tight mt-1 p-1">
        A gallery of projects shipped by the Enlight Community.
      </h2>
      <div className="relative mx-auto px-2 mt-6">
        {projects.length > 190 ? (
          <div className="flex flex-wrap justify-center">
            {projects
              .sort(
                (a, b) =>
                  new Date(b.date).getTime() - new Date(a.date).getTime()
              )
              .map((project, i) => (
                <div
                  className="p-4 max-h-100 bg-gray-100 shadow-md rounded-sm m-4 bg-clip-padding text-center flex items-center"
                  key={project.date}
                >
                  <div>
                    <p className="text-sm tracking-tight text-gray-700">
                      {new Date(project.date).toLocaleDateString()}
                    </p>
                    <p className="text-xl font-bold tracking-tight text-gray-900">
                      <a target="_blank" href={`/projects/${project.postId}`}>
                        {project.title}
                      </a>
                    </p>

                    <p className="max-w-xs">
                      {project.description && project.description.length > 100
                        ? project.description.substring(0, 100) + "..."
                        : project.description}
                    </p>
                    <p>
                      <a
                        className="text-gray-700 underline"
                        href={`/user/${project.id}`}
                        target="_blank"
                      >
                        <img
                          src={
                            project.photoURL
                              ? project.photoURL
                              : "/img/avatar.png"
                          }
                          className="h-8 rounded-full shadow-md m-2 inline"
                        />
                        {project.username}
                      </a>
                      {project.courses && project.courses.includes("wd0") && (
                        <span className="text-xs rounded-full bg-gray-300 text-gray-800 tracking-tight font-bold p-2 ml-2">
                          Cohort 4
                        </span>
                      )}
                      {project.courses && project.courses.includes("wd1") && (
                        <span className="text-xs rounded-full bg-gray-300 text-gray-800 tracking-tight font-bold text-white p-2 ml-2">
                          Cohort 5
                        </span>
                      )}
                    </p>

                    {project.img && (
                      <a
                        target="_blank"
                        href={project.demo}
                        className="flex justify-center"
                      >
                        <img
                          className="max-h-32 rounded-md shadow-md m-2 hover:opacity-75"
                          src={project.img}
                        />
                      </a>
                    )}

                    <div className="mt-4 text-center">
                      {project.github && (
                        <a
                          target="_blank"
                          href={project.github}
                          className="shadow-md rounded-md bg-blue-800 text-white p-1 text-sm mr-2 hover:opacity-75"
                        >
                          source
                        </a>
                      )}

                      {project.demo && (
                        <a
                          target="_blank"
                          href={project.demo}
                          className="shadow-md rounded-md bg-yellow-800 text-white p-1 text-sm hover:opacity-75"
                        >
                          demo
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        ) : (
          <SpinnerContainer>
            <Spinner name="ball-scale-multiple" color="#438cee" />
          </SpinnerContainer>
        )}
      </div>
    </>
  );
};

export default Gallery;
